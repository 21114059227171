import React from "react";
import AccordionItem from "../../components/AccordionItem";
import { Accordion } from "react-bootstrap";

export default class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: null
    };
  }

  componentDidMount() {
    prompt("enableBack")
  }


  render () {
    const { activeKey } = this.state
    const faqItems = [
    {
      title: "What is LX ProductScan?",
      content:
        "LX ProductScan is a mobile application that allows you to scan a variety of smart tags to receive a variety of important information to assist in verifying the products’ authenticity."
    },
    {
      title: "What is a CQR?",
      content:
        "A CQR is a unique quick response code that is proprietary to the LXConnect infrastructure. These unique QR codes are embedded with a cryptographic hash, that is not able to be counterfeited or duplicated, and provides an enhanced level of trust between a consumer and the manufacturer."
    },
    {
      title: "How to scan a CQR Code in LX ProductScan",
      content: (
        <div>
          1. First open the LX ProductScan App.
          <br /> 2. Then tap the scan button at the bottom of the app. <br /> 3.
          The camera screen will open. Focus the camera on the CQR code by
          gently tapping the code with your finger on the phone screen and
          follow the guides on the screen. <br /> 4. If the scan does not
          complete immediately, move the mobile device closer to the object
          holding the CQR. <br /> 5. If the scan does not complete, move the
          mobile device further away from the object holding the CQR. <br /> 6.
          Repeat steps 3 and 4 as necessary until the scan completes. <br /> 7.
          If the scan does not complete, check and make sure that the QR code is
          on a flat surface, and that there is light overhead to illuminate the
          pixels."
        </div>
      )
    },
    {
      title:
        "Where can I find the Privacy Policy and Terms of Use for LX ProductScan?",
      content:
        "You can find both the Privacy Policy and Terms of Use for LX ProductScan by navigating to the Settings Page. Once you are at the Settings Page tap the About button. You will be direct to the About Page which has both links for Privacy Policy and Terms of Use."
    },
    {
      title: "How do I view a previous scan that I made in LX ProductScan?",
      content:
        "You can view a complete history of your previous scans on your device if you have the settings selected to save your history turned on in the Settings page. To do this simply navigate to the Settings Page and make sure that under “History”, “Save” is set to on. Then when you make a scan it will be saved in your in history on the History Page. You can go to your history page and click on any scan to go to that URL later."
    },
    {
      title:
        "How do I change my preference to opt-in and opt-out of Location Services on LX ProductScan?",
      content:
        "We at LocatorX value your privacy, and security above all else and have made this as simple as possible for you. You can access these mobile device settings for LX ProductScan by simply clicking on the “View” button under “Device Settings” in the Settings Page. Tap to go through to the device’s settings and change the app’s permissions there."
    }
  ];

  return (
    <div className="container text-white py-3">
      <div className="row">
        <div className="col-2 align-items-center d-flex">
          <i className="fas fa-question-circle fa-2x" />
        </div>
        <div className="col-10 pt-2">
          <h5>FAQs</h5>
        </div>
        <div className="col-10 ml-auto">
          <Accordion activeKey={activeKey} onSelect={(activeKey)=>{
            this.setState({activeKey})
          }} >
            {faqItems.map((element, index) => {
              return (
                <AccordionItem
                  key={index}
                  index={index}
                  activeKey={activeKey}
                  title={element.title}
                  content={element.content}
                />
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );}
}
