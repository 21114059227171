import React from "react";
import IconWithArrow from "../../components/IconWithArrow";

export default class About extends React.Component {

  componentDidMount() {
    prompt("enableBack")
  }

  render() {
    return (
      <div className="container text-white py-3 vh-100">
        <div className="row">
          <div className="col-2 align-items-center d-flex">
            <i className="fas fa-info-circle fa-2x" />
          </div>
          <div className="col-10 pt-2">
            <h5>About</h5>
          </div>
          <div className="col-11 ml-auto">
            <IconWithArrow
              label="Privacy Policy"
              showArrow={true}
              link="https://wiki.locatorx.com/privacy-policy/"
              newWindow={false}
              showUnderline={true}
            />
          </div>
          <div className="col-11 ml-auto">
            <IconWithArrow
              label="Terms & Conditions"
              showArrow={true}
              link="https://wiki.locatorx.com/terms-of-use/"
              newWindow={false}
              showUnderline={true}
            />
          </div>
          <div className="col-11 ml-auto">
            <IconWithArrow
              label="LocatorX"
              showArrow={true}
              link="https://www.locatorx.com/"
              newWindow={false}
              showUnderline={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
