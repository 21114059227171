import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import Truncate from "react-truncate";

export function HistoryItem(props) {
  const { label, date, url } = props;
  const browserTimeZone = moment.tz.guess()
  return (
    <div className="w-100 text-white d-flex my-2">
      <div className="m-3 text-center">
        <i className="fas fa-qrcode fa-2x" />
      </div>
      <div className="border-bottom w-100">
        <a
          href={url}
          rel="noopener noreferrer"
          className="d-flex justify-content-between text-white"
        >
          <div className="w-100">
            <div className="eventHistory">
              <Truncate  width={250} ellipsis="...">
                {label}
              </Truncate>
            </div>
            <div className="dateHistory">
              <Moment date={date} format="MMM DD, YYYY hh:mm A" tz={browserTimeZone} />
            </div>
          </div>
          <div className="my-auto">
            <i className="fas fa-angle-right fa-2x"></i>
          </div>
        </a>
      </div>
    </div>
  );
}

export default class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recentScans: prompt("loadAssetHistory")
    };
  }


  render() {
    const { recentScans = [] } = this.state;
    const convertedRecentScans = recentScans.length > 0 ? JSON.parse(recentScans) : [];
   
    
    return (
      <div className="container py-3 h-100 history">
        <div className="col-12 text-center my-2">
          <h4>History</h4>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            {convertedRecentScans.map((element, index) => {
              const { tag, timeOfVisitString, url } = element;
              const label = tag !== undefined ? tag : url
              return (
                  <HistoryItem
                  key={index}
                  label={label}
                  url={url}
                  date={timeOfVisitString}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
