import React from "react";
import qrIllustration from "../../assets/qrIllustration.png";

export default class Welcome extends React.Component {

  render() {
    return (
      <div className="container py-3 h-100">
        <div className="d-flex flex-column justify-content-between home-page">
          <div className="col-md-12 text-center">
            <span className="text-white font-weight-bold">
              Simply click the scan button to activate your camera. Then focus
              your camera on the QR code to view information about the product.
            </span>
          </div>
          <div className="col-md-12 my-3 text-center">
            <img src={qrIllustration} className="welcome-image" alt="Scan" />
          </div>
          <div className="w-100">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block shadow my-auto"
              onClick={() => {
                prompt("scan");
              }}
            >
              Scan
            </button>
          </div>
        </div>
      </div>
    );
  }
}
