import React from "react";
import { Accordion, Card } from "react-bootstrap";

export default function AccordionItem(props) {

    const { title, content, index, activeKey } = props;

    return (
      <Card className="card-item">
        <Card.Header>
          <Accordion.Toggle
            as={Card.Header}
            variant="link"
            eventKey={index}
            className="d-flex justify-content-between"
          >
            <span>{title}</span>
            <div className={activeKey !== index ? "" : "d-none"}>
              <i className="fas fa-plus" />
            </div>
            <div className={activeKey === index ? "" : "d-none"}>
              <i className="fas fa-minus" />
            </div>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={index}>
          <Card.Body>{content}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
}
